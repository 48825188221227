.header-top {
  background: #659999;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #f4791f, #659999);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #f4791f, #659999); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
font-family: American Typewriter;
}
.header-drawer {
  font-family: American Typewriter;
}
.landing-grid{
  background: #659999;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f4791f, #659999);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f4791f, #659999); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.resume-grid {
  background: #659999;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f4791f, #659999);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f4791f, #659999); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  top:0%;
  left:0%;
  width:100%;
  height: 100%;
}
.resume-text {
  background-color: black;
  opacity: .8;
  width: 75%;
  margin-left: 12%;
  margin-top: 1%;
  padding-left: 2%;
  border-radius: 10px;
  padding-top: 2%;
  color: white;
  font-size: 17px;
  font-family: American Typewriter;
}
.resume-text b {
  font-size: 20px;
  font-family: American Typewriter;
}
.resume-text p {
  font-size: 17px;
  font-family: American Typewriter;
}
.resume-text a {
  color: white;
}
.resume-text h2 {
  font-family: American Typewriter;
  font-size: 40px;
}
.avatar-img {
  height: 300px;
  /* padding-top: 4em; */
  border-radius: 45px;
  margin-top: 5%;
}
.banner-text {
  background-color: black;
  opacity: .8;
  width: 80%;
  margin: auto;
  /* margin-top: -30px; */
  border-radius: 20px;
}
.banner-text h1 {
  font-size: 60px;
  font-weight: bold;
  color: white;
  font-family: Apple Chancery;

}
.banner-text hr {
  border-top: 5px dotted white;
  width: 55%;
  margin: auto;
}
.banner-text p {
  color: white;
  font-size: 25px;
  padding: 1em;
  font-family: Apple Chancery;
}
.social-links {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}
.social-links i {
  color: white;
  font-size: 4em;
  /* padding: 8px; */
}
.projects-grid {
  display: flex;
  justify-content: space-between;
  font-family: American Typewriter;

}
.projects-grid h5 {
  font-family: American Typewriter;
  color: #699896;
  font-weight: bold;
}
.contact-grid {
  text-align: center;
  justify-content: center;
  background: white;
  width: 65%;
  height:60%;
  border-radius: 50px 15px;
}
.about-body {
  background: #659999;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f4791f, #659999);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f4791f, #659999); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* margin:auto; */
  justify-content: center;
  padding-top: 2em;
  width: 100%;
  height: 100%;
  position: fixed;
}
.about-grid {
  text-align: center;
  justify-content: center;
  /* position:absolute; */
  /* background: white; */
  /* width: 65%;
  height:80%; */
  border-radius: 50px 15px;
}
.about-grid p {
  width: 75%;
  /* text-align: center; */
    margin: auto;
    padding-top: 1em;
    font-family: American Typewriter;
    font-size: 120%;
}
.about-grid h2 {
  font-family: American Typewriter;
  /* padding-top: 1%; */
}
.contact-grid h2 {
  font-family: American Typewriter;
  margin-top: 9%;
  color:black;
}
.contact-grid hr {
  background: #659999;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f4791f, #659999);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f4791f, #659999); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  margin-bottom: 5%;
}
.contactbutton {
  background: #659999;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f4791f, #659999);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f4791f, #659999); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius:10px;
  cursor:pointer;
  font-family:American Typewriter;
	font-size:23px;
	font-weight:bold;
	padding:15px 31px;
  text-shadow:0px 1px 0px #659999;
  margin-top: 4%;
}
.textfield {
  font-family: American Typewriter;
}

.contact-body {
  background: #659999;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f4791f, #659999);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f4791f, #659999); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* margin:auto; */
  justify-content: center;
  padding-top: 3em;
  width: 100%;
  height: 100%;
  position: fixed;

}
.tabs {
  font-family: American Typewriter;
}
.sent-body {
  background: #659999;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f4791f, #659999);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f4791f, #659999); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  font-family: American Typewriter;
  /* text-align: center; */
  padding-top: 15em;
  /* font-size: 145%; */
  width: 100%;
  height: 100%;
  position: fixed;
  justify-content: center;
}

.sent-grid {
  width: 40%;
  height: 40%;
  text-align: center;
    margin: auto;
    padding-top: 4%;
    font-family: American Typewriter;
    font-size: 145%;
    background: white;
    border-radius: 15px 50px;
}
.sent-grid p {
  /* width: 95%; */
  text-align: center;
    margin: auto;
    padding-top: 1%;
    font-family: American Typewriter;
    font-size: 145%;
}
.project-links {
  font-family: American Typewriter;
  color: black;
}
.download-button {
	box-shadow: 0px 0px 0px 2px #ebeef5;
	background:linear-gradient(to bottom, #081833 5%, #02080f 100%);
	background-color:#081833;
	border-radius:10px;
	border:1px solid #ebeef5;
	/* display:inline-block; */
	cursor:pointer;
	color:#ffffff;
	font-family:American Typewriter;
	font-size:23px;
	font-weight:bold;
	padding:15px 31px;
	text-shadow:0px 1px 0px #24272e;
}
.download-button:hover {
	background:linear-gradient(to bottom, #000000 5%, #000000 100%);
	background-color:#000000;
}
.download-button:active {
	position:relative;
	top:1px;
}
.download-button:focus {
    outline: none;
}
